:root {
    --white: #ffffff;
    --black: #000000;
    --text: #333333;
    --red: #FF0000;

    --primary: #0092FF;
    --primary100: #004B83;
    --primaryLight: rgba(0, 146, 255, 0.08);
    --primaryBg: #E6F3FE;
    --primaryDark: #001423;
    --primaryText: #0B164C;

    --border: #0000001A;
    --border100: #FFFFFF66;
    --border200: #EAECF0;
    --lightText: #667085;
    --lightText100: #FFFFFF99;

    --gray: #DDDDDD;

    --label: #727272;
    --disabledBg: #72727226;
    --disabledText: #727272CC;

    --font: "Chivo", sans-serif;
    --fontMont: "Montserrat", sans-serif;
    --bodySize: 16px;
}