.space-between {
	@include fpos(center, space-between);
}

.flex-center {
	@include fpos(center, center);
}

.flex-start {
	@include fpos(flex-start, flex-start);
}

.justify-start {
	@include fpos(center, flex-start);
}

.justify-end {
	@include fpos(center, flex-end);
}

.align-flex-end {
	@include fpos(flex-end, center);
}

.align-flex-center {
	@include falign(center);
}

.flex-wrap {
	flex-wrap: wrap;
}

.text-center {
	text-align: center;
}

.uppercase {
	text-transform: uppercase;
}

.capitalize {
	text-transform: capitalize;
}

.underline {
	text-decoration: underline;
}

.center {
	text-align: center;
}

.right {
	text-align: right;
}

.left {
	text-align: left;
}

.pointer {
	cursor: pointer;
}

.mx-auto {
	margin: 0 auto;
}

.relative {
	position: relative;
}

.visually_hidden {
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}

input {
	&:disabled {
		opacity: 0.6;
	}

	&[disabled] {
		opacity: 0.6;
	}
}

.disabled {
	opacity: 0.6;
}

.no-scroll {
	overflow: hidden;
}

.desktop {
	@include tab-breakpoint {
		display: none;
	}
}

.mobile {
	display: none;

	@include tab-breakpoint {
		display: block;
	}
}

.link {
	position: relative;
	text-decoration: none;

	&::before {
		content: "";
		position: absolute;
		left: 50%;
		bottom: 0;
		width: 0;
		height: 2px;
		background: var(--primary);
		transition: all 0.2s ease-in;
		transform: translateX(-50%);
	}

	&:hover,
	&.active {
		color: var(--primary);

		&::before {
			width: 100%;
		}
	}
}

.containerBox {
	width: 100%;
	max-width: 1300px;
	padding: 0 30px;
	margin: 0 auto;

	@include mobile {
		padding: 0 20px;
	}

	@include mobile-xs {
		padding: 0 15px;
	}
}

.common-padding {
	padding: 50px 0;

	@include desktop-breakpoint {
		padding: 40px 0;
	}

	@include tab-breakpoint {
		padding: 30px 0;
	}
}

.app-link {
	img {
		transition: .2s all ease-in-out;

		@include desktop-xs {
			max-width: 160px;
		}

		@include tab {
			max-width: 140px;
		}

		@include mobile {
			max-width: 120px;
		}

		&:hover {
			transform: scale(1.1);

			@include tab {
				transform: none;
			}
		}
	}
}