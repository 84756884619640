*,
*::after,
*::before {
    box-sizing: border-box;
    scrollbar-width: thin;
    outline: 0;
}

html {
    scroll-behavior: smooth;
}

html,
body {
    min-height: 100%;
}

body {
    padding: 0;
    margin: 0;
    overflow-y: auto;
    font-family: var(--font);
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--text);
    font-size: var(--bodySize);
    -webkit-tap-highlight-color: transparent;

    @supports (-webkit-appearance: none) {
        overflow: overlay;
    }
}

a {
    text-decoration: none;
    cursor: pointer;
}

button {
    cursor: pointer;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    background: transparent;
    -webkit-text-fill-color: var(--black);
    -webkit-box-shadow: 0 0 0px 1000px var(--white) inset;
    transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--white) inset !important;
    box-shadow: 0 0 0 30px var(--white) inset !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
}

img {
    max-width: 100%;
    height: auto;
    display: block;
}

:root {
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    ::-webkit-scrollbar-track {
        background: var(--white);
    }

    ::-webkit-scrollbar-thumb {
        @include radius(10px);
        background: var(--lightText);
    }

    ::-webkit-scrollbar-thumb:hover {
        background: var(--lightText);
    }

    ::-webkit-scrollbar-corner {
        background-color: transparent;
        border-color: transparent;
    }

    ::-webkit-scrollbar-track-piece {
        background-color: var(--white);
    }

    ::-webkit-scrollbar-thumb:vertical {
        height: 8px;
        @include radius(10px);
        background-color: var(--lightText);
    }

    ::-moz-scrollbar {
        width: 8px;
        height: 8px;
    }

    ::-webkit-scrollbar-track {
        background: var(--white);
    }

    ::-moz-scrollbar-track {
        background: var(--white);
    }

    ::-moz-scrollbar-thumb {
        @include radius(10px);
        background: var(--lightText);
    }

    ::-moz-scrollbar-thumb:hover {
        background: var(--lightText);
    }
}