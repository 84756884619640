@import './var.scss';

.fs_56 {
    font-size: 56px;
    line-height: 66px;

    @include desktop-lg {
        font-size: 52px;
    }

    @include desktop-sm {
        font-size: 50px;
        line-height: 58px;
    }

    @include desktop-xs {
        font-size: 48px;
    }

    @include tab {
        font-size: 45px;
        line-height: 52px;
    }

    @include tab-sm2 {
        font-size: 42px;
    }

    @include tab-breakpoint {
        font-size: 36px;
        line-height: 42px;
    }

    @include mobile {
        font-size: 32px;
        line-height: 40px;
    }
}

.fs_44 {
    font-size: 44px;
    line-height: 52px;

    @include desktop-lg {
        font-size: 40px;
    }

    @include desktop-sm {
        font-size: 38px;
        line-height: 46px;
    }

    @include desktop-xs {
        font-size: 36px;
    }

    @include tab {
        font-size: 32px;
        line-height: 40px;
    }

    @include tab-sm {
        font-size: 28px;
        line-height: 36px;
    }

    @include mobile {
        font-size: 24px;
        line-height: 32px;
    }
}

.fs_36 {
    font-size: 36px;
    line-height: 44px;

    @include desktop-sm {
        font-size: 32px;
    }

    @include desktop-breakpoint {
        font-size: 30px;
        line-height: 38px;
    }

    @include tab {
        font-size: 28px;
    }

    @include tab-sm2 {
        font-size: 24px;
        line-height: 32px;
    }

    @include mobile {
        font-size: 20px;
        line-height: 28px;
    }
}

.fs_32 {
    font-size: 32px;

    @include desktop-breakpoint {
        font-size: 30px;
    }

    @include tab {
        font-size: 28px;
    }

    @include tab-sm2 {
        font-size: 26px;
    }

    @include tab-breakpoint {
        font-size: 24px;
    }

    @include mobile-sm {
        font-size: 20px;
    }

    @include mobile-xs {
        font-size: 18px;
    }
}

.fs_20 {
    font-size: 20px;

    @include desktop-xs {
        font-size: 18px;
    }

    @include tab-sm2 {
        font-size: 16px;
    }
}

.fs_18 {
    font-size: 18px;

    @include desktop {
        font-size: 16px;
    }

    @include tab-sm {
        font-size: 14px;
    }
}

.fs_16 {
    font-size: 16px;

    @include desktop-sm {
        font-size: 14px;
    }
}

.fs_14 {
    font-size: 14px;

    @include desktop-sm {
        font-size: 13px;
    }
}

.fw400 {
    font-weight: 400;
}

.fw500 {
    font-weight: 500;
}

.fw600 {
    font-weight: 600;
}

.fw700 {
    font-weight: 700;
}

.primary {
    color: var(--primary);
}

.white {
    color: var(--white);
}

.light {
    color: var(--lightText);
}

.gray {
    color: var(--gray);
}