.m-0 {
    margin: 0;
}

$spaces: (
    0,
    2,
    4,
    5,
    6,
    8,
    10,
    12,
    15,
    16,
    18,
    20,
    24,
    25,
    28,
    30,
    32,
    36,
    40,
    44,
    48,
    50,
    60
);
$sides: (
    top,
    bottom,
    left,
    right
);

@each $space in $spaces {
    @each $side in $sides {
        .m#{str-slice($side, 0, 1)}-#{$space} {
            margin-#{$side}: #{$space}px;
        }
    }
}

@media screen and (max-width: 1700px) {
    @each $space in $spaces {
        @each $side in $sides {
            .m#{str-slice($side, 0, 1)}-xl-#{$space} {
                margin-#{$side}: #{$space}px;
            }
        }
    }
}

@media screen and (max-width: 1480px) {
    @each $space in $spaces {
        @each $side in $sides {
            .m#{str-slice($side, 0, 1)}-lg-#{$space} {
                margin-#{$side}: #{$space}px;
            }
        }
    }
}

@media screen and (max-width: 992px) {
    @each $space in $spaces {
        @each $side in $sides {
            .m#{str-slice($side, 0, 1)}-md-#{$space} {
                margin-#{$side}: #{$space}px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    @each $space in $spaces {
        @each $side in $sides {
            .m#{str-slice($side, 0, 1)}-sm-#{$space} {
                margin-#{$side}: #{$space}px;
            }
        }
    }
}

@media screen and (max-width: 576px) {
    @each $space in $spaces {
        @each $side in $sides {
            .m#{str-slice($side, 0, 1)}-xs-#{$space} {
                margin-#{$side}: #{$space}px;
            }
        }
    }
}