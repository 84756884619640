// Popups

.modal_content {
    position: relative;
    background-color: var(--white);
}

.close_modal {
    background-color: transparent;
    border: 0;
    position: absolute;
    top: 16px;
    right: 16px;
    height: 32px;
    width: 32px;
    padding: 0;
    cursor: pointer;
    z-index: 10;
}

.popup_backdrop {
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(44px);

    &+.cdk-global-overlay-wrapper {
        .cdk-overlay-pane {
            width: 100%;
            max-height: calc(100vh - 50px);
            margin: 20px;
        }

        .mdc-dialog__surface {
            --mdc-dialog-container-shape: 16px;
            overflow: hidden;
        }
    }
}

.account_popup {
    --mat-dialog-container-max-width: 1240px;
}

.otp_popup {
    --mat-dialog-container-max-width: 800px;
}

.video_popup {
    --mat-dialog-container-max-width: unset;
}

// Form fields

.label {
    color: var(--primaryText);
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    display: block;
    margin-bottom: 8px;
    font-family: var(--fontMont);

    @include tab-sm {
        font-size: 13px;
    }

    &.required {
        &::after {
            content: '*';
            display: inline-block;
        }
    }

    &.gray {
        color: var(--gray);
    }
}

.mat-mdc-form-field {
    --mdc-outlined-text-field-outline-color: #0B164C66;
    --mdc-outlined-text-field-hover-outline-color: #0092FF;
    --mdc-outlined-text-field-focus-outline-color: #0092FF;
    --mdc-outlined-text-field-input-text-placeholder-color: #0B164C99;
    --mdc-outlined-text-field-disabled-input-text-color: #0B164C66;
    --mdc-outlined-text-field-input-text-color: #0B164C;
    --mdc-outlined-text-field-container-shape: 8px;
    width: 100%;

    .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }

    .mdc-text-field--outlined {
        @include tab-sm2 {
            padding-right: max(12px, var(--mdc-outlined-text-field-container-shape));
            padding-left: max(12px, var(--mdc-outlined-text-field-container-shape));
        }

        .mdc-text-field__input {
            font-family: var(--fontMont);
            font-size: 16px;
            font-weight: 500;

            @include desktop {
                font-size: 14px;
            }
        }
    }

    &.primary_wrap {
        --mdc-outlined-text-field-outline-color: #CBCAD74D;
        --mdc-outlined-text-field-hover-outline-color: #CBCAD74D;
        --mdc-outlined-text-field-focus-outline-color: #CBCAD74D;
        --mdc-outlined-text-field-input-text-placeholder-color: #DDDDDDB2;
        --mdc-outlined-text-field-input-text-color: #FFFFFF;
    }
}

.mat-mdc-form-field-error {
    font-size: 13px;
    color: var(--red);
}

// Datepicker

.mat-datepicker-content {
    .mat-calendar {
        --mat-datepicker-calendar-date-today-outline-color: #0092FF;
        --mat-datepicker-calendar-date-selected-state-background-color: #0092FF;
        --mat-datepicker-calendar-date-focus-state-background-color: #0092ff4d;
        --mat-datepicker-calendar-date-hover-state-background-color: #0092ff4d;
    }
}

// Buttons
.button_with_icon {
    .mdc-button__label {
        @include fpos(center, center);

        img {
            transition: .3s all cubic-bezier(0.39, 0.575, 0.565, 1);
            margin-left: 8px;
            width: 20px;
            height: 20px;
        }
    }

    &:hover {
        .mdc-button__label {
            img {
                transform: translateX(10px);
            }
        }
    }
}

// Carousel

.owl-theme {
    .owl-dots {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 20px;

        @include desktop-sm {
            bottom: 30px;
        }

        @include tab-breakpoint {
            bottom: 10px;
        }

        .owl-dot {
            span {
                background-color: rgba($color: #0092FF, $alpha: .6);
                margin: 4px;

                @include mobile {
                    width: 6px;
                    height: 6px;
                    margin: 2px;
                }
            }

            &.active {
                span {
                    width: 38px;
                    background-color: var(--primary);

                    @include mobile {
                        width: 24px;
                    }
                }
            }
        }
    }
}

.bgDark {
    .owl-theme {
        .owl-dots {

            .owl-dot {
                span {
                    background-color: rgba($color: #FFFFFF, $alpha: .7);
                }

                &.active {
                    span {
                        background-color: var(--white);
                    }
                }
            }
        }
    }
}