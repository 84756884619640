.btn {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-size: var(--bodySize);
    font-weight: 500;
    border: 1px solid transparent;
    padding: 0 20px;
    font-family: var(--font);
    overflow: hidden;
    white-space: nowrap;
    transition: 0.2s transform ease-in-out;
    will-change: transform;
    z-index: 0;

    &.btn_white {
        background-color: var(--white);
        color: var(--primary);
        border-color: var(--white);
    }

    &.md {
        height: 44px;
    }

    &.btn_effect {
        text-decoration: none;

        &::after {
            background-color: var(--primary);
            border-radius: 8px;
            content: '';
            display: block;
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            transform: translate(-100%, 0) rotate(10deg);
            transform-origin: top left;
            transition: 0.2s transform ease-out;
            will-change: transform;
            z-index: -1;
        }

        &:hover::after {
            transform: translate(0, 0);
        }

        &:hover {
            border: 2px solid transparent;
            color: var(--white);
            transform: scale(1.05);
            will-change: transform;
        }
    }
}